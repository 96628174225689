import { useEffect, useState } from "react";

import { cn } from "~/lib/ui";

import type { Button } from "./button";
import { LoadingButton } from "./loading-button";

export const TriButton = ({
  children,
  isLoading = false,
  doneContent = "✓ Done",
  loadingContent = "Loading...",
  loadingIndicatorClassName,
  className,
  icon,
  ...props
}: React.ComponentProps<typeof Button> & {
  icon?: string;
  isLoading?: boolean;
  doneContent?: React.ReactNode;
  doneContentTimeout?: number;
  loadingContent?: React.ReactNode;
  loadingIndicatorClassName?: string;
}) => {
  const [state, setState] = useState<"loading" | "done" | "idle">("idle");
  const content =
    state === "idle"
      ? children
      : state === "loading"
        ? loadingContent
        : doneContent;
  useEffect(() => {
    setState(isLoading ? "loading" : "idle");
  }, [isLoading]);

  return (
    <LoadingButton
      {...props}
      className={cn(className, "flex items-center gap-2 overflow-hidden")}
      isLoading={isLoading}
      loadingIndicatorClassName={loadingIndicatorClassName}
    >
      {icon && !isLoading && (
        <img src={icon} className={`h-6 w-6 fill-white`} alt={"icon"} />
      )}
      <span
        className="animate-quick-reveal font-extrabold"
        key={isLoading.toString()}
      >
        {content}
      </span>
    </LoadingButton>
  );
};
